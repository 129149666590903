<template>
    <div>
        <el-dialog title="请选择门店加入采购车" :visible.sync="farenVisible" width="30%" :before-close="handleClose" class="new-dialog">
            <div style="padding: 5px 2px;" class="checkbox">
                <el-checkbox v-model="allSelected">全选</el-checkbox>
            </div>
          
         <div style="max-height: 500px;overflow-y: auto;">
            <div v-for="(item,index) in cartList" :key="index" class="subject">
                <el-checkbox v-model="item.checked" >{{item.companyName}}</el-checkbox>
                <div style="display:flex;align-items:center;">
                    <div style="position:relative;margin-right:20px;">
                        <!-- <img class="lcar" src="../../assets/img/lcar.png" alt="" style="margin-right:20px;">
                                            <div class="lcardot3">{{item.already}}</div> -->
                        已加购：{{item.num}}
                    </div>
                    <el-input-number v-model="item.quantity" :min="countStep" :step="countStep" size="small" ></el-input-number>
                </div>
            </div>
         </div>
          
            <span slot="footer" class="dialog-footer">
                <el-button style="width:100%;background:#0054bc;color:#fff" @click="realAddCar">确 定</el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
export default {
    props: {
        farenVisible: {
            type: Boolean,
            default: false
        },
       cartList: {
           type: Array,
           default: () => {
               return []
           }
       },
       countStep: {
           type: Number,
           default: 1
       },
    },
    data() {
        return {
            

        }
    },
    mounted() {
       console.log(this.countStep,1);
       
    },
    computed: {
        allSelected: {
            get() {
                const newarr = this.cartList.filter(item => !item.disabled);
                return newarr.every((item) => {
                    return item.checked

                })
                   
            },
            set(value) {
                console.log(value);
                if (value) {
                    this.cartList.forEach(item => {
                        if (!item.disabled) {
                            item.checked = true
                        }
                    });
                } else {
                    this.cartList.forEach(item => {
                        if (!item.disabled) {
                            item.checked = false
                        }
                    });
                }
            }
        }
    },
    methods: {

    
        handleClose() {
           this.$emit('closeDialog',false)
        },
        realAddCar(){
             this.$emit('realAddCar',this.cartList)
        },
    }
}
</script>

<style lang="scss" scoped>
.subject {
    border-top: 1px solid #f2f2f2;
    padding: 5px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.address {
    padding: 12px 0;
    border-bottom: 1px dashed #e6e6e6;
    width: 100%;
    display: flex;
}
.my-dialog {
    .dialog-body {
        // display: flex;
        // // justify-content: center;
        // flex-direction: column;
        // align-items: center;

        font-size: 14px;
        color: #a0a0a0;
        img {
            width: 172px;
            height: 172px;
        }
    }
}

.you-dialog {
    .dialog-body {
        // display: flex;
        // // justify-content: center;
        // flex-direction: column;
        // align-items: center;

        font-size: 14px;
        color: #a0a0a0;
        img {
            width: 172px;
            height: 172px;
        }
    }
}
.upload-demo {
    width: 80px;
    height: 80px;
    background: #f3f5f5;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: #ebeeee;
    }
}
.btn {
    &:hover {
        background: #0687fe !important;
    }
}
.imgList {
    position: relative;
    i {
        position: absolute;
        top: 2px;
        right: 1px;
        color: #c2c4c0;
        cursor: pointer;
    }
}
.first {
    margin-top: -4px;
}
</style>
<style lang="scss" >
.my-dialog {
    width: 450px;
    height: 460px;
    border-radius: 10px 10px 10px 10px !important;
    .el-dialog__footer {
        text-align: center !important;
    }
    //     .el-dialog__title {
    //     font-weight: bold !important;
    //     font-size: 25px !important;
    //     color: #1a1a1a !important;
    // }
}
.three-dialog {
    width: 500px;

    border-radius: 10px 10px 10px 10px !important;
    .el-dialog__footer {
        text-align: center !important;
    }
    .el-dialog__header {
        padding: 0 !important;
    }
    .el-radio-group {
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden; /* 当内容溢出容器时隐藏 */
        word-wrap: break-word; /* 当一个单词超出容器时自动换行 */

        /*滚动条里面小方块*/
        &::-webkit-scrollbar-thumb {
            background-color: #ebeaef;
            border-radius: 10px;
        }
        /*滚动条整体样式*/
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
        /*滚动条里面轨道*/
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 10px;
        }
    }
    .el-radio {
        white-space: wrap !important;
    }

    .el-dialog__body {
        // height: 300px;
        // overflow: auto;
    }
}
.you-dialog {
    width: 500px;
    height: 520px;
    border-radius: 10px 10px 10px 10px !important;
    .el-dialog__footer {
        text-align: center !important;
        margin-top: 60px !important;
    }
    .el-dialog__title {
        font-weight: bold !important;
        font-size: 25px !important;
        color: #1a1a1a !important;
    }
}
</style>